<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import ProductCategoryForm from "./forms/product-category-form.vue";
import ProductCategoryShow from "./show/show.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import AddButton from "@/components/buttons/add-button.vue";

/**
 * Customers Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    AddButton,
    ProductCategoryForm,
    ProductCategoryShow,
    CustomTable
  },
  data() {
    return {
      formModalId:'product-category-form-modal',
      showModalId:'product-category-show-modal',
      pageHeader:{
        title: "Categorías de productos",
        breadcrumbs: [
          {
            text: "Categorías de productos",
            href: "/product-categories",
            active: true
          }
        ],
      },
      table:{
        url:'/product-categories',
        params: {
          'with_trashed': 1,
        },
        fields:[
          { key: "id", label: "Cod", sortable: true },
          { key: "name", label: "Nombre", sortable: true },
          { key: "color", label: "Color", sortable: false },
          { key: "product_category_parent.name", label: "Padre", sortable: false },
          { key: "slug", label: "Slug", sortable: true },
        ],
        formatted_fields:[
          'color'
        ]
      },
      formSelectedItemId:null,   
      showSelectedItemId:null,
      clone:false
    };
  },
  methods: {
    showEditModal(item, clone = false) {
      this.formSelectedItemId = item?.id || null;
      this.clone = clone;
      this.$bvModal.show(this.formModalId);
    },
    showViewModal(item) {
      this.showSelectedItemId = item?.id || null;
      this.$bvModal.show(this.showModalId);
    },
    onSuccess(){
      this.$refs.customerTable.refresh();
    },
    formatter(field, item){
      if(field === 'color'){
        return '<span class="qlk-preview-color" title="'+item[field]+'" style="background-color:'+item[field]+'"></span>';
      }
      return '';
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <ProductCategoryForm :modal-id="formModalId" :id="formSelectedItemId" @onSuccess="onSuccess" :clone="clone"></ProductCategoryForm>
            <ProductCategoryShow :modal-id="showModalId" :id="showSelectedItemId"></ProductCategoryShow>
            <CustomTable
              :url="table.url"
              :fields="table.fields"
              :formatted-fields="table.formatted_fields"
              :formatter="formatter"
              :params="table.params"
              @edit="showEditModal"
              ref="customerTable"
              :show-view="false"
              :showSoftDelete="false"
              @show="showViewModal"
              :show-modal-id="showModalId"
            >
              <template v-slot:header-actions>
                <AddButton @click="showEditModal"></AddButton>
              </template>
            </CustomTable>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>